import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Card,
  Col,
  Row,
  Input,
  Flex,
  Button,
  DatePicker,
  InputNumber,
  Upload,
  Popover,
} from "antd";
import PageHeader from "../../../components/pageHeader";
import UploadUtils from "../../../utilities/uploadUtils";
import SortDraggableList from "../../../components/SortDraggableList";
import ReactQuill from "react-quill";
import dayjs from "dayjs";
import { QuestionCircleOutlined } from "@ant-design/icons";
import roleAndPermissions from "../../../constants/permissions"
import AuthChecker from "../../../components/authChecker"

const { TextArea } = Input;

const SortableCoreRefundPolicy = ({ data, setData, form, mode }) => {
  const coreRefundPolicyItem =
    data?.corerefundpolicy?.map((item, index) => ({
      id: index.toString(),
      ...item,
    })) || [];

  const renderCoreRefundPolicyContent = (field) => (
    <div>
      <Form.Item
        label="Title"
        name={[field.name, "name"]}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Core Refund & Policy Description"
        name={[field.name, "description"]}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <ReactQuill theme="snow" rows={4} readOnly={mode === "View" ? true : false} />
      </Form.Item>
    </div>
  );

  return (
    <SortDraggableList
      items={coreRefundPolicyItem}
      renderContent={renderCoreRefundPolicyContent}
      setData={setData}
      form={form}
      formField="corerefundpolicy"
      addContent={true}
      showRemoveOption={true}
      cardTitle="Core Refund & Policy"
    />
  );
};

const CoreRefundPolicy = ({ mode, formFor, editLayout, title }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(formFor?.en?.data);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    // console.log(values);
    setLoading(true);
    try {
      values.feature_image = UploadUtils.processUploadImage(
        values.feature_image
      );
      const seo_setting = [
        {
          id: formFor.seo_setting?.[0]?.id,
          meta_title: values.meta_title,
          meta_description: values.meta_description,
          feature_image: values.feature_image,
        },
      ];

      setData(values);
      await editLayout(values, "Core Refund Policy", seo_setting);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      disabled={mode === "View" || loading === true}
      layout="vertical"
      initialValues={data}
      onFinish={(values) => handleSubmit(values)}
    >
      <PageHeader title={`${title?.replace("-", " ")} Page`} />
      <Row gutter={16} className="mt30">

        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Core Refund & Policy Main Title">
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Card>
        </Col>

        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Core Refund & Policy Section">
            <SortableCoreRefundPolicy
              data={data}
              setData={setData}
              form={form}
              mode={mode}
            />
          </Card>
        </Col>

        {/* Search Engine Listing */}
        <AuthChecker
          notPermissionCase={
            <Col span={24} style={{ marginBottom: 30 }}>
              <Card size="small" title="Search Engine Listing">
                <Form.Item
                  label="Meta Title"
                  name="meta_title"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  label="Meta Description"
                  name="meta_description"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <TextArea disabled />
                </Form.Item>
                <Form.Item
                  label={
                    <span>
                      Feature Image{" "}
                      <Popover
                        placement="right"
                        title="W * H"
                        content="1200 x 675 OR 1600 x 900"
                      >
                        <QuestionCircleOutlined />
                      </Popover>
                    </span>
                  }
                  name="feature_image"
                  {...UploadUtils.formItemProps}
                  wrapperCol={{ span: 24 }}
                >
                  <Upload {...UploadUtils.buttonPreviewProps} disabled>
                    <UploadUtils.CardContent />
                  </Upload>
                </Form.Item>
              </Card>
            </Col>}
          permissions={[roleAndPermissions.PageManagement.createSEO, roleAndPermissions.PageManagement.updateSEO]}>
          <Col span={24} style={{ marginBottom: 30 }}>
            <Card size="small" title="Search Engine Listing">
              <Form.Item
                label="Meta Title"
                name="meta_title"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Meta Description"
                name="meta_description"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <TextArea />
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    Feature Image{" "}
                    <Popover
                      placement="right"
                      title="W * H"
                      content="1200 x 675 OR 1600 x 900"
                    >
                      <QuestionCircleOutlined />
                    </Popover>
                  </span>
                }
                name="feature_image"
                {...UploadUtils.formItemProps}
                wrapperCol={{ span: 24 }}
              >
                <Upload {...UploadUtils.buttonPreviewProps} >
                  <UploadUtils.CardContent />
                </Upload>
              </Form.Item>
            </Card>
          </Col>
        </AuthChecker>

        <Col span={24}>
          <Flex justify="end" style={{ padding: 24, paddingRight: 0 }} gap={16}>
            <Form.Item noStyle>
              <Button type="primary" htmlType="submit"    loading={loading}
                disabled={loading}>
                Update Page
              </Button>
            </Form.Item>
          </Flex>
        </Col>
      </Row>
    </Form>
  );
};

export default CoreRefundPolicy;
