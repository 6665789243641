import React, { useEffect, useState } from "react";
import {
  Form,
  Card,
  Col,
  Row,
  Input,
  Flex,
  Button,
  Upload,
  Popover,
  Select,
} from "antd";
import PageHeader from "../../../components/pageHeader";
import Dragger from "antd/es/upload/Dragger";
import UploadUtils from "../../../utilities/uploadUtils";
import ReactQuill from "react-quill";
import AboutAffiliationSort from "../../../components/about-us-draggable/AboutAffiliationSort";
import WhyYouChooseSort from "../../../components/about-us-draggable/WhyYouChooseSort";
import AboutBannerSort from "../../../components/about-us-draggable/AboutBannerSort";
import AboutLocationSort from "../../../components/about-us-draggable/AboutLocationSort";
import { QuestionCircleOutlined } from "@ant-design/icons";
import AuthChecker from "../../../components/authChecker";
import roleAndPermissions from "../../../constants/permissions";

const { TextArea } = Input;

const AboutUsPageForm = ({ mode, formFor, editLayout, title }) => {
  const [data, setData] = useState(formFor?.en?.data);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      values.banner_image = UploadUtils.processUploadImage(values.banner_image);

      values.banner1_image = UploadUtils.processUploadImage(
        values.banner1_image
      );
      values.banner2_image = UploadUtils.processUploadImage(
        values.banner2_image
      );
      // values.banner3_image = UploadUtils.processUploadImage(
      //   values.banner3_image
      // );

      values.banner3_images = values.banner3_images.map((i) => ({
        image: UploadUtils.processUploadImage(i.image),
      }));

      values.body_banner_image = UploadUtils.processUploadImage(
        values.body_banner_image
      );
      values.why_you_choose = values.why_you_choose.map((i) => ({
        name: i.name,
        image: UploadUtils.processUploadImage(i.image),
        description: i.description,
      }));

      values.feature_image = UploadUtils.processUploadImage(
        values.feature_image
      );
      values.affiliations = values.affiliations.map((i) => ({
        affiliation_image: UploadUtils.processUploadImage(i.affiliation_image),
      }));
      values.aboutLocation.tennessee = UploadUtils.processUploadImage(
        values.aboutLocation.tennessee
      );
      values.aboutLocation.florida = UploadUtils.processUploadImage(
        values.aboutLocation.florida
      );
      values.aboutLocation.texas = UploadUtils.processUploadImage(
        values.aboutLocation.texas
      );

      const seo_setting = [
        {
          id: formFor.seo_setting?.[0]?.id,
          meta_title: values.meta_title,
          meta_description: values.meta_description,
          feature_image: values.feature_image,
        },
      ];
      setData(data);
      await editLayout(values, "About Us", seo_setting);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      disabled={mode === "View" || loading === true}
      layout="vertical"
      initialValues={data}
      // initialValues={{
      //   affiliations: [
      //     { affiliation_image: [] },
      //     { affiliation_image: [] },
      //     { affiliation_image: [] },
      //     { affiliation_image: [] },
      //     { affiliation_image: [] },
      //   ],
      //   why_you_choose: [
      //     {
      //       name: "",
      //       description: "",
      //       image: [],
      //     },
      //     {
      //       name: "",
      //       description: "",
      //       image: [],
      //     },
      //     {
      //       name: "",
      //       description: "",
      //       image: [],
      //     },
      //     {
      //       name: "",
      //       description: "",
      //       image: [],
      //     },
      //   ],
      // }}
      onFinish={(values) => handleSubmit(values)}
    >
      <PageHeader title={`${title.replace("-", " ")} Page`} />
      <Row gutter={16} className="mt30">
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Banner Section1">
            <Form.Item
              label="Banner Title1"
              name="banner_title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Banner Description1"
              name="banner_description"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <ReactQuill
                theme="snow"
                rows={4}
                readOnly={mode === "View" ? true : false}
              />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Banner Image 1{" "}
                  <Popover placement="right" title="W * H" content="735 x 507">
                    <QuestionCircleOutlined />
                  </Popover>
                </span>
              }
              name="banner_image"
              {...UploadUtils.formItemProps}
              wrapperCol={{ span: 24 }}
            >
              <Upload {...UploadUtils.buttonPreviewProps}>
                <UploadUtils.CardContent />
              </Upload>
            </Form.Item>
          </Card>
        </Col>
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Affiliations Section">
            <Form.Item
              label="Affiliation Section Title"
              name="affiliation_title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <AboutAffiliationSort data={data} setData={setData} form={form} />
            {/* <Form.List name="affiliations">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    // gap: 30,
                    justifyContent: "space-between",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {fields.map((field, index) => (
                    <Card
                      size="small"
                      title={`Affiliation ${index + 1}`}
                      key={field.key}
                    >
                      <Form.Item
                        label="Affiliation Image"
                        name={[field.name, "affiliation_image"]}
                        {...UploadUtils.formItemProps}
                        wrapperCol={{ span: 24 }}
                      >
                        <Upload {...UploadUtils.buttonPreviewProps}>
                          <UploadUtils.CardContent />
                        </Upload>
                      </Form.Item>
                    </Card>
                  ))}
                </div>
              )}
            </Form.List> */}
          </Card>
        </Col>
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Banner Section2">
            <Form.Item
              label="Banner2 Title"
              name="banner2_title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Banner2 Description"
              name="banner2_description"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <ReactQuill
                theme="snow"
                rows={4}
                readOnly={mode === "View" ? true : false}
              />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Banner2 Image{" "}
                  <Popover placement="right" title="W * H" content="460 x 533">
                    <QuestionCircleOutlined />
                  </Popover>
                </span>
              }
              name="banner2_image"
              {...UploadUtils.formItemProps}
              wrapperCol={{ span: 24 }}
            >
              <Upload {...UploadUtils.buttonPreviewProps}>
                <UploadUtils.CardContent />
              </Upload>
            </Form.Item>
          </Card>
        </Col>
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Video Section">
            {/* <Form.Item
              label="Main Title"
              name="youtube_main_title"
              rules={[
                {
                  required: true,
                  message: "This field is required"
                }
              ]}
            >
              <Input />
            </Form.Item> */}
            {/* <Form.Item
              label="Sub Title"
              name="youtube_sub_title"
              rules={[
                {
                  required: true,
                  message: "This field is required"
                }
              ]}
            >
              <Input />
            </Form.Item> */}

            {/* <Form.Item
                  label="Name"
                  name={["talk_to_live", "name"]}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item> */}

            <Form.Item
              label={"Link Url"}
              name={["youtube_link", "redirect_path"]}
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>

            {/* <Form.Item
              label={"Select Link Type"}
              name={["youtube_link", "link_type"]}
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Select
                options={[
                  // {
                  //   value: "internal",
                  //   label: "Internal",
                  // },
                  {
                    value: "external",
                    label: "External"
                  }
                ]}
              />
            </Form.Item> */}

            {/* <Form.Item
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.talk_to_live?.link_type !==
                currentValues.talk_to_live?.link_type
              }
              noStyle
            >
              {({ getFieldValue }) => {
                let type = getFieldValue(["youtube_link", "link_type"]);

                return (
                  <>
                    {type === "external" && (
                      <Form.Item
                        label={"Link Url"}
                        name={["youtube_link", "redirect_path"]}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    )}
                  
                  </>
                );
              }}
            </Form.Item> */}
          </Card>
        </Col>

        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Why You Choose Us Section">
            <Form.Item
              label="Why You Choose Us Section Title"
              name="why_you_choose_us_section_title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <WhyYouChooseSort data={data} setData={setData} form={form} />
            {/* <Form.List name="why_you_choose">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    gap: 30,
                    flexDirection: "row",
                  }}
                >
                  {fields.map((field, index) => (
                    <Card
                      size="small"
                      title={`Why You Choose ${index + 1}`}
                      key={field.key}
                    >
                      <Form.Item
                        label="Name"
                        name={[field.name, "name"]}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Description"
                        name={[field.name, "description"]}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <ReactQuill theme="snow" rows={4} />
                      </Form.Item>
                      <Form.Item
                        label="Image"
                        name={[field.name, "image"]}
                        {...UploadUtils.formItemProps}
                        wrapperCol={{ span: 24 }}
                      >
                        <Upload {...UploadUtils.buttonPreviewProps}>
                          <UploadUtils.CardContent />
                        </Upload>
                      </Form.Item>
                    </Card>
                  ))}
                </div>
              )}
            </Form.List> */}
          </Card>
        </Col>

        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Banner Section3">
            <Form.Item
              label="Banner3 Title"
              name="banner3_title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Banner3 Description"
              name="banner3_description"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <ReactQuill
                theme="snow"
                rows={4}
                readOnly={mode === "View" ? true : false}
              />
            </Form.Item>
            <AboutBannerSort data={data} setData={setData} form={form} />
            {/* <Form.List name={"banner3_images"}>
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    gap: 30,
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: 30,
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {fields.map((field, index) => (
                      <Card
                        size="small"
                        title={`Banner 3 Image ${index + 1}`}
                        key={field.key}
                        extra={
                          <MinusCircleOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        }
                      >
                        <Form.Item
                          label="Image"
                          name={[field.name, "image"]}
                          {...UploadUtils.formItemProps}
                          wrapperCol={{ span: 24 }}
                        >
                          <Upload {...UploadUtils.buttonPreviewProps}>
                            <UploadUtils.CardContent />
                          </Upload>
                        </Form.Item>
                      </Card>
                    ))}
                  </div>

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Banner 3 Image
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form.List> */}
          </Card>
        </Col>

        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Body Banner Section">
            <Form.Item
              label="Body Banner Title"
              name="body_banner_title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Body Banner Description"
              name="body_banner_description"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <ReactQuill
                theme="snow"
                rows={4}
                readOnly={mode === "View" ? true : false}
              />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Body Banner Image{" "}
                  <Popover placement="right" title="W * H" content="609 x 519">
                    <QuestionCircleOutlined />
                  </Popover>
                </span>
              }
              name="body_banner_image"
              {...UploadUtils.formItemProps}
              wrapperCol={{ span: 24 }}
            >
              <Upload {...UploadUtils.buttonPreviewProps}>
                <UploadUtils.CardContent />
              </Upload>
            </Form.Item>
          </Card>
        </Col>
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Location Images">
            {/* <AboutLocationSort data={data} setData={setData} form={form} /> */}
            <div style={{ display: "flex", gap: "20px" }}>
              <Card size="small" title="Oviedo Florida">
                <div>
                  <Form.Item
                    label="Oviedo Florida Address"
                    name={["aboutLocation", "florida_name"]}
                    wrapperCol={{ span: 24 }}
                  >
                    <ReactQuill readOnly={mode === "View" ? true : false} />
                  </Form.Item>
                  <Form.Item
                    label="Oviedo Florida"
                    name={["aboutLocation", "florida"]}
                    {...UploadUtils.formItemProps}
                    wrapperCol={{ span: 24 }}
                  >
                    <Upload {...UploadUtils.buttonPreviewProps}>
                      <UploadUtils.CardContent />
                    </Upload>
                  </Form.Item>
                </div>
              </Card>

              <Card size="small" title="Dallas Texas">
                <div>
                  <Form.Item
                    label="Dallas Texas Address"
                    name={["aboutLocation", "texas_name"]}
                    wrapperCol={{ span: 24 }}
                  >
                    <ReactQuill readOnly={mode === "View" ? true : false} />
                  </Form.Item>
                  <Form.Item
                    label="Dallas Texas"
                    name={["aboutLocation", "texas"]}
                    {...UploadUtils.formItemProps}
                    wrapperCol={{ span: 24 }}
                  >
                    <Upload {...UploadUtils.buttonPreviewProps}>
                      <UploadUtils.CardContent />
                    </Upload>
                  </Form.Item>
                </div>
              </Card>

              <Card size="small" title="Goodlettsville Tennesse">
                <div>
                  <Form.Item
                    label="Goodlettsville, Tennesse Address"
                    name={["aboutLocation", "tennessee_name"]}
                    wrapperCol={{ span: 24 }}
                  >
                    <ReactQuill readOnly={mode === "View" ? true : false} />
                  </Form.Item>

                  <Form.Item
                    label="Goodlettsville, Tennessee"
                    name={["aboutLocation", "tennessee"]}
                    {...UploadUtils.formItemProps}
                    wrapperCol={{ span: 24 }}
                  >
                    <Upload {...UploadUtils.buttonPreviewProps}>
                      <UploadUtils.CardContent />
                    </Upload>
                  </Form.Item>
                </div>
              </Card>
            </div>
          </Card>
        </Col>

        <AuthChecker
          notPermissionCase={
            <Col span={24} style={{ marginBottom: 30 }}>
              <Card size="small" title="Search Engine Listing">
                <Form.Item
                  label="Meta Title"
                  name="meta_title"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  label="Meta Description"
                  name="meta_description"
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <TextArea disabled />
                </Form.Item>
                <Form.Item
                  label={
                    <span>
                      Feature Image{" "}
                      <Popover
                        placement="right"
                        title="W * H"
                        content="1200 x 675 OR 1600 x 900"
                      >
                        <QuestionCircleOutlined />
                      </Popover>
                    </span>
                  }
                  name="feature_image"
                  {...UploadUtils.formItemProps}
                  wrapperCol={{ span: 24 }}
                >
                  <Upload {...UploadUtils.buttonPreviewProps} disabled>
                    <UploadUtils.CardContent />
                  </Upload>
                </Form.Item>
              </Card>
            </Col>
          }
          permissions={[
            roleAndPermissions.PageManagement.createSEO,
            roleAndPermissions.PageManagement.updateSEO,
          ]}
        >
          <Col span={24} style={{ marginBottom: 30 }}>
            <Card size="small" title="Search Engine Listing">
              <Form.Item
                label="Meta Title"
                name="meta_title"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Meta Description"
                name="meta_description"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <TextArea />
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    Feature Image{" "}
                    <Popover
                      placement="right"
                      title="W * H"
                      content="1200 x 675 OR 1600 x 900"
                    >
                      <QuestionCircleOutlined />
                    </Popover>
                  </span>
                }
                name="feature_image"
                {...UploadUtils.formItemProps}
                wrapperCol={{ span: 24 }}
              >
                <Upload {...UploadUtils.buttonPreviewProps}>
                  <UploadUtils.CardContent />
                </Upload>
              </Form.Item>
            </Card>
          </Col>
        </AuthChecker>
        <Col span={24}>
          <Flex justify="end" style={{ padding: 24, paddingRight: 0 }} gap={16}>
            <Form.Item noStyle>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
              >
                Update Page
              </Button>
            </Form.Item>
          </Flex>
        </Col>
      </Row>
    </Form>
  );
};

export default AboutUsPageForm;
