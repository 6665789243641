import React, { useEffect, useState } from "react";
import PageHeader from "../../components/pageHeader";
import { Row, Col, Button, List, Popconfirm, Spin, message } from "antd";
import AuthChecker from "../../components/authChecker";
import roleAndPermission from "../../constants/permissions";
import { formList } from "../../constants/formList";
import endpoints from "../../constants/endpoints";
import requests from "../../utilities/api";

const CMSlist = () => {
  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [singlePageLoading, setSinglePageLoading] = useState(true);
  const [mode, setMode] = useState("");
  const [pages, setPages] = useState([]);
  const [slug, setSlug] = useState("")
  const [title, setTitle] = useState("")
  const [formFor, setFormFor] = useState("");

  const getCMSPages = async () => {
    try {
      const api = requests();
      setListLoading(true);
      const response = await api.get(endpoints.get_cms_pages_list, { limit: -1 }, true);
      setPages(response?.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setListLoading(false);
    }
  };

  const getCMSSinglePages = async (id) => {
    try {
      setSinglePageLoading(true);
      const api = requests();
      let response = await api.get([endpoints.get_cms_pages_list, id].join("/"))

      if (response.status === "Success") {
        setFormFor(response?.data);
        setSinglePageLoading(false);
      } else {
        setFormFor("")
        setSinglePageLoading(false)
      }
    } catch (error) {
      setSinglePageLoading(false);
    }
  }

  const editLayout = async (data, title, seo_setting) => {
    try {
      // setLoading(true);
      // setSinglePageLoading(true);
      const api = requests();
      let response = await api.put(
        endpoints.edit_cms_pages.replace("id", formFor.id),
        {
          group: "page",
          status: "INACTIVE",
          seo_setting: seo_setting,
          en: { data },
          bn: {},
          title: title,
        },
        true
      );

      if (response.status === "Success") {
        message.success(response.message);
        setPages(
          pages.map((i) => {
            if (i.id === response?.data.id) {
              i.en = response?.data?.en;
              i.seo_setting = response?.data.seo_setting;
            }
            return i;
          })
        );
        setFormFor(response?.data?.en);
        setMode("");
        // setLoading(false);
        // setSinglePageLoading(false);
      } else {
        message.error(response.message);
        setMode("");
        setFormFor("");
        // setLoading(false);
        // setSinglePageLoading(false);
      }
    } catch (error) {
      console.log(error)
      // setLoading(false);
      // setSinglePageLoading(false);
    }
  };

  useEffect(() => {
    getCMSPages();
  }, []);
  return (
    <>
      <PageHeader title="CMS Pages List" />
      <Row gutter={[24, 24]} className="mt-4">
        <Col span={8}>
          <AuthChecker permissions={[roleAndPermission.PageManagement.view]}>
            <List
              size="small"
              itemLayout="horizontal"
              bordered
              loading={listLoading}
              dataSource={pages}
              header={<strong>Pages List</strong>}
              renderItem={(item) => {
                return (
                  <List.Item
                    actions={[
                      <AuthChecker
                        permissions={[roleAndPermission.PageManagement.view]}
                      >
                        <Button
                          type="text"
                          size="small"
                          key="0"
                          onClick={() => {
                            setMode("View");
                            // setFormFor(item);
                            setSlug(item.slug)
                            setTitle(item.title)
                            getCMSSinglePages(item.id)
                          }}
                        >
                          View
                        </Button>
                      </AuthChecker>,
                      <AuthChecker
                        permissions={[roleAndPermission.PageManagement.update]}
                      >
                        <Button
                          type="text"
                          size="small"
                          key="0"
                          onClick={() => {
                            setMode("Edit");
                            // setFormFor(item);
                            setSlug(item.slug)
                            setTitle(item.title)
                            getCMSSinglePages(item.id)
                          }}
                        >
                          Edit
                        </Button>
                      </AuthChecker>,
                    ]}
                  >
                    <div>
                      <div>{item.title}</div>
                      {/* <div
                        style={{
                          color: "#aaa",
                          fontSize: "0.7em",
                          fontStyle: "italic",
                        }}
                      >
                        Updated {dayjs(item.updated_at).fromNow()}
                      </div> */}
                    </div>
                  </List.Item>
                );
              }}
            />
          </AuthChecker>
        </Col>
        <Col span={16}>
          {mode && (
            // <Spin spinning={singlePageLoading}>
            <Row>
              <Col span={24}>
                <FormToRender
                  key={formFor.id}
                  formFor={formFor}
                  mode={mode}
                  editLayout={editLayout}
                  slug={slug}
                  title={title}
                  singlePageLoading={singlePageLoading}
                />
              </Col>
            </Row>
            // </Spin>
          )}
        </Col>
      </Row>
    </>
  );
};

const FormToRender = ({ formFor, mode, editLayout, slug, singlePageLoading, title }) => {
  // const Component = formList[formFor?.slug?.toUpperCase()];
  const Component = formList[slug?.toUpperCase()];

  return (
    <>
      {/* {singlePageLoading ? */}
      <Spin
        spinning={singlePageLoading}
        // tip="Loading"
        size="large"
        style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70vh" }}
      >
        {/* {!singlePageLoading  && <Component mode={mode} formFor={formFor} editLayout={editLayout} />} */}
       <Component mode={mode} title={title} formFor={formFor || {}} editLayout={editLayout} />
      </Spin>
      {/* } */}
    </>
    // <>
    //   {singlePageLoading ?
    //     <Spin
    //       spinning={singlePageLoading}
    //        tip="Loading" 
    //        size="large"
    //       style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70vh" }}
    //        />
    //     : <Component mode={mode} formFor={formFor} editLayout={editLayout} />}
    // </>
  );
};

export default CMSlist;
