import React, { useEffect, useState } from "react";
import {
  Form,
  Card,
  Col,
  Row,
  Input,
  Flex,
  Button,
  Select,
  Upload,
  Popover,
} from "antd";
import PageHeader from "../../../components/pageHeader";
import Dragger from "antd/es/upload/Dragger";
import UploadUtils from "../../../utilities/uploadUtils";
import { MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import FooterAcceptedPaymentSort from "../../../components/footer-draggable/FooterAcceptedPaymentSort";
import FooterAboutLinksSort from "../../../components/footer-draggable/FooterAboutLinksSort";
import FooterImportantLinksSort from "../../../components/footer-draggable/FooterImportantLinksSort";
import roleAndPermissions from "../../../constants/permissions"
import AuthChecker from "../../../components/authChecker"

const { TextArea } = Input;

const FooterForm = ({ mode, formFor, editLayout, title }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(formFor?.en?.data);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      values.feature_image = UploadUtils.processUploadImage(
        values.feature_image
      );
      const seo_setting = [
        {
          id: formFor.seo_setting?.[0]?.id,
          meta_title: values.meta_title,
          meta_description: values.meta_description,
          feature_image: values.feature_image,
          // feature_image: UploadUtils.processUploadImage(values.feature_image),
        },
      ];

      values.accepted_payments = values.accepted_payments.map((i) => ({
        payment_image: UploadUtils.processUploadImage(i.payment_image),
      }));
      setData(data);
      await editLayout(values, "Footer", seo_setting);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <Form
      form={form}
      disabled={mode === "View" || loading === true}
      layout="vertical"
      initialValues={data}
      onFinish={(values) => handleSubmit(values)}
    >
      <PageHeader title={`${title}`} />
      <Row gutter={16} className="mt30">
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Address">
            <Form.Item
              label="Address"
              name="address"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Card>
        </Col>
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Customer Care">
            <Form.Item
              label="Customer Care Number"
              name="phone_number"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Card>
        </Col>

        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Accepted Payments">
            <FooterAcceptedPaymentSort
              data={data}
              setData={setData}
              form={form}
            />
            {/* <Form.List name="accepted_payments">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    gap: 30,
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: 30,
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {fields.map((field, index) => (
                      <Card
                        size="small"
                        title={`Payment Image ${index + 1}`}
                        key={field.key}
                        extra={
                          <MinusCircleOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        }
                      >
                        <Form.Item
                          label="Payment Image"
                          name={[field.name, "payment_image"]}
                          {...UploadUtils.formItemProps}
                          wrapperCol={{ span: 24 }}
                        >
                          <Upload {...UploadUtils.buttonPreviewProps}>
                            <UploadUtils.CardContent />
                          </Upload>
                        </Form.Item>
                      </Card>
                    ))}
                  </div>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Payment
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form.List> */}
          </Card>
        </Col>

        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Social media Link">
            <Form.Item
              label="Instagram Link"
              name="instagram_link"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input type="url" />
            </Form.Item>
            <Form.Item
              label="Facebook Link"
              name="facebook_link"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input type="url" />
            </Form.Item>
            <Form.Item
              label="Youtube Link"
              name="youtube_link"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input type="url" />
            </Form.Item>
          </Card>
        </Col>

        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="About Links">
            <FooterAboutLinksSort data={data} setData={setData} form={form} />
            {/* <Form.List name="about_links">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    gap: 30,
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: 30,
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {fields.map((field, index) => (
                      <Card
                        size="small"
                        title={`Link ${index + 1}`}
                        key={field.key}
                        extra={
                          <MinusCircleOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        }
                      >
                        <Form.Item
                          label="Link Name"
                          name={[field.name, "link_name"]}
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label="Select Link Type"
                          name={[field.name, "link_type"]}
                        >
                          <Select
                            options={[
                              {
                                value: "internal",
                                label: "Internal",
                              },
                              {
                                value: "external",
                                label: "External",
                              },
                            ]}
                          />
                        </Form.Item>
                        <Form.Item
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.about_links?.[index]?.link_type !==
                            currentValues.about_links?.[index]?.link_type
                          }
                          noStyle
                        >
                          {({ getFieldValue }) => {
                            let type = getFieldValue([
                              "about_links",
                              index,
                              "link_type",
                            ]);
                            console.log(type);
                            return (
                              <>
                                {type === "external" && (
                                  <Form.Item
                                    label="Link Url"
                                    name={[field.name, "redirect_path"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "This field is required",
                                      },
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>
                                )}
                                {type === "internal" && (
                                  <>
                                    <Form.Item
                                      label="Link Url"
                                      name={[field.name, "redirect_path"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "This field is required",
                                        },
                                      ]}
                                    >
                                      <Input addonBefore={process.env.REACT_APP_CUSTOMER_SITE_URL} />
                                    </Form.Item>
                                  </>
                                )}
                              </>
                            );
                          }}
                        </Form.Item>
                      </Card>
                    ))}
                  </div>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add About Links
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form.List> */}
          </Card>
        </Col>
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Important Links">
            <FooterImportantLinksSort
              data={data}
              setData={setData}
              form={form}
            />
            {/* <Form.List name="important_links">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    gap: 30,
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: 30,
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {fields.map((field, index) => (
                      <Card
                        size="small"
                        title={`Link ${index + 1}`}
                        key={field.key}
                        extra={
                          <MinusCircleOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        }
                      >
                        <Form.Item
                          label="Link Name"
                          name={[field.name, "link_name"]}
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label="Select Link Type"
                          name={[field.name, "link_type"]}
                        >
                          <Select
                            options={[
                              {
                                value: "internal",
                                label: "Internal",
                              },
                              {
                                value: "external",
                                label: "External",
                              },
                            ]}
                          />
                        </Form.Item>
                        <Form.Item
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.important_links?.[index]?.link_type !==
                            currentValues.important_links?.[index]?.link_type
                          }
                          noStyle
                        >
                          {({ getFieldValue }) => {
                            let type = getFieldValue([
                              "important_links",
                              index,
                              "link_type",
                            ]);
                            console.log(type);
                            return (
                              <>
                                {type === "external" && (
                                  <Form.Item
                                    label="Link Url"
                                    name={[field.name, "redirect_path"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "This field is required",
                                      },
                                    ]}
                                  >
                                    <Input addonBefore={process.env.REACT_APP_CUSTOMER_SITE_URL} />
                                  </Form.Item>
                                )}
                                {type === "internal" && (
                                  <>
                                    <Form.Item
                                      label="Link Url"
                                      name={[field.name, "redirect_path"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "This field is required",
                                        },
                                      ]}
                                    >
                                     <Input addonBefore={process.env.REACT_APP_CUSTOMER_SITE_URL} />
                                    </Form.Item>
                                  </>
                                )}
                              </>
                            );
                          }}
                        </Form.Item>
                      </Card>
                    ))}
                  </div>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Important Links
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form.List> */}
          </Card>
        </Col>
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Customer Counting Section">
            <Form.Item
              label="Total Number of Customer"
              name="total_customer"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Customer Section Title"
              name="customer_section_title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Customer Section Description"
              name="customer_section_description"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Card>
        </Col>

        <AuthChecker notPermissionCase={ <Col span={24} style={{ marginBottom: 30 }}>
            <Card size="small" title="Search Engine Listing">
              <Form.Item
                label="Meta Title"
                name="meta_title"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input disabled/>
              </Form.Item>
              <Form.Item
                label="Meta Description"
                name="meta_description"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <TextArea disabled/>
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    Feature Image{" "}
                    <Popover
                      placement="right"
                      title="W * H"
                      content="1200 x 675 OR 1600 x 900"
                    >
                      <QuestionCircleOutlined />
                    </Popover>
                  </span>
                }
                name="feature_image"
                {...UploadUtils.formItemProps}
                wrapperCol={{ span: 24 }}
              >
                <Upload {...UploadUtils.buttonPreviewProps} disabled>
                  <UploadUtils.CardContent />
                </Upload>
              </Form.Item>
            </Card>
          </Col>} permissions={[roleAndPermissions.PageManagement.createSEO, roleAndPermissions.PageManagement.updateSEO]}>


          <Col span={24} style={{ marginBottom: 30 }}>
            <Card size="small" title="Search Engine Listing">
              <Form.Item
                label="Meta Title"
                name="meta_title"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Meta Description"
                name="meta_description"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <TextArea />
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    Feature Image{" "}
                    <Popover
                      placement="right"
                      title="W * H"
                      content="1200 x 675 OR 1600 x 900"
                    >
                      <QuestionCircleOutlined />
                    </Popover>
                  </span>
                }
                name="feature_image"
                {...UploadUtils.formItemProps}
                wrapperCol={{ span: 24 }}
              >
                <Upload {...UploadUtils.buttonPreviewProps}>
                  <UploadUtils.CardContent />
                </Upload>
              </Form.Item>
            </Card>
          </Col>
        </AuthChecker>
        <Col span={24}>
          <Flex justify="end" style={{ padding: 24, paddingRight: 0 }} gap={16}>
            <Form.Item noStyle>
              <Button type="primary" htmlType="submit"    loading={loading}
                disabled={loading}>
                Update Page
              </Button>
            </Form.Item>
          </Flex>
        </Col>
      </Row>
    </Form>
  );
};

export default FooterForm;
