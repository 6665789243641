import React from "react";
import { Card, Button, Form, Row, Col, Input, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

const SortableItem = ({
  id,
  index,
  //   renderContent,
  remove,
  showRemoveOption,
  cardTitle,
  field,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id, index });

  return (
    <Card
      size="small"
      title={`${cardTitle} ${index + 1}`}
      ref={setNodeRef}
      style={{
        transform: `translate3d(${transform?.x || 0}px, ${
          transform?.y || 0
        }px, 0)`,
        cursor: "-moz-grab",
        transition,
        ...attributes.style,
      }}
      {...attributes}
      {...listeners}
      hoverable={true}
      extra={
        showRemoveOption && (
          <MinusCircleOutlined onClick={() => remove(index)} />
        )
      }
    >
      {/* {renderContent()} */}
      <Row gutter={16} style={{ margin: 0 }}>
        <Col span={12}>
          <Form.Item
            label="Item Name"
            name={[field.name, "item_name"]}
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Item Type"
            name={[field.name, "item_type"]}
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Select
              options={[
                {
                  value: "link",
                  label: "Link",
                },
                {
                  value: "dropdown",
                  label: "Dropdown",
                },
              ]}
            />
          </Form.Item>
        </Col>
        {/* <Col span={24}> */}
        <Form.Item
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.items?.[index]?.item_type !==
            currentValues.items?.[index]?.item_type
          }
          noStyle
          //   dependencies={["item_type"]}
        >
          {({ getFieldValue }) => {
            let type = getFieldValue(["items", index, "item_type"]);

            return (
              <>
                {type === "link" && (
                  <>
                    <Col span={12}>
                      <Form.Item
                        label={"Select Link Type"}
                        name={[field.name, "link_type"]}
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Select
                          options={[
                            {
                              value: "internal",
                              label: "Internal",
                            },
                            {
                              value: "external",
                              label: "External",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.items?.[index]?.link_type !==
                          currentValues.items?.[index]?.link_type
                        }
                        noStyle
                      >
                        {({ getFieldValue }) => {
                          let type = getFieldValue([
                            "items",
                            index,
                            "link_type",
                          ]);
                          return (
                            <>
                              {type === "external" && (
                                <Form.Item
                                  label={"Link Url"}
                                  name={[field.name, "redirect_path"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "This field is required",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              )}
                              {type === "internal" && (
                                <>
                                  <Form.Item
                                    label={"Link Url"}
                                    name={[field.name, "redirect_path"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "This field is required",
                                      },
                                    ]}
                                  >
                                    <Input addonBefore={process.env.REACT_APP_CUSTOMER_SITE_URL} />
                                  </Form.Item>
                                </>
                              )}
                            </>
                          );
                        }}
                      </Form.Item>
                    </Col>
                  </>
                )}
                {type === "dropdown" && (
                  <>
                    {/* <Form.Item name={[field.name, "dropdown_type"]}>
                                      <Select
                                        placeholder="Please select droprown type"
                                        options={[
                                          {
                                            value: "Collection",
                                            label: "Catalogs",
                                          },
                                          {
                                            value: "Other",
                                            label: "Other",
                                          },
                                        ]}
                                      />
                                    </Form.Item> */}
                    <Form.List
                      name={[field.name, "options"]}
                      initialValue={[1]}
                    >
                      {(innerfields, { add, remove }) => (
                        <>
                          {/* <Row>
                                                  <Col span={24}> */}
                          {innerfields.map((innerfield, indexNew) => (
                            <Row style={{ margin: 0 }} gutter={12}>
                              <Col span={6}>
                                <Form.Item
                                  label={indexNew === 0 ? "Label" : ""}
                                  name={[innerfield.name, "label"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "This field is required",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col span={4}>
                                <Form.Item
                                  // label={"Select Link Type"}
                                  label={indexNew === 0 ? "Link Type" : ""}
                                  name={[innerfield.name, "link_type"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "This field is required",
                                    },
                                  ]}
                                >
                                  <Select
                                    options={[
                                      {
                                        value: "internal",
                                        label: "Internal",
                                      },
                                      {
                                        value: "external",
                                        label: "External",
                                      },
                                    ]}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  shouldUpdate={(prevValues, currentValues) =>
                                    prevValues.items?.[index]?.options?.[
                                      indexNew
                                    ]?.link_type !==
                                    currentValues.items?.[index]?.options?.[
                                      indexNew
                                    ]?.link_type
                                  }
                                  noStyle
                                >
                                  {({ getFieldValue }) => {
                                    let type = getFieldValue([
                                      "items",
                                      index,
                                      "options",
                                      indexNew,
                                      "link_type",
                                    ]);
                                    return (
                                      <>
                                        {type === "external" && (
                                          <Form.Item
                                            // label={"Link Url"}
                                            label={
                                              indexNew === 0 ? "Link Url" : ""
                                            }
                                            name={[innerfield.name, "url"]}
                                            rules={[
                                              {
                                                required: true,
                                                message: "This field is required",
                                              },
                                            ]}
                                          >
                                            <Input />
                                          </Form.Item>
                                        )}
                                        {type === "internal" && (
                                          <>
                                            <Form.Item
                                              // label={"Link Url"}
                                              label={
                                                indexNew === 0 ? "Link Url" : ""
                                              }
                                              name={[innerfield.name, "url"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "This field is required",
                                                },
                                              ]}
                                            >
                                              <Input addonBefore={process.env.REACT_APP_CUSTOMER_SITE_URL} />
                                            </Form.Item>
                                          </>
                                        )}
                                      </>
                                    );
                                  }}
                                </Form.Item>
                              </Col>
                              <Col span={2}>
                                <div
                                  style={{
                                    marginTop: indexNew === 0 ? 36 : 4,
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <MinusCircleOutlined
                                    onClick={() => {
                                      remove(innerfield.name);
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ))}
                          {/* </Col>
                                                </Row> */}
                          <Col span={24}>
                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                              >
                                Add Options
                              </Button>
                            </Form.Item>
                          </Col>
                        </>
                      )}
                    </Form.List>
                  </>
                )}
              </>
            );
          }}
        </Form.Item>
        {/* </Col> */}
      </Row>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <i className="ri-drag-drop-line" style={{ fontSize: "18px" }}></i>
      </div>
    </Card>
  );
};

const HeaderDraggableSort = ({ data, setData, form }) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10 },
    })
  );

  const items =
    data?.items?.map((item, index) => ({
      id: index.toString(),
      ...item,
    })) || [];

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item?.id === active?.id);
      const newIndex = items.findIndex((item) => item?.id === over?.id);

      if (oldIndex !== -1 && newIndex !== -1) {
        const updatedItems = arrayMove(items, oldIndex, newIndex);
        setData((prevData) => ({
          ...prevData,
          items: updatedItems.map(({ id, ...rest }) => rest),
        }));

        form.setFieldsValue({
          items: updatedItems.map(({ id, ...rest }) => rest),
        });
      }
    }
  };

  return (
    <div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={items?.map((item) => item.id)}
          strategy={verticalListSortingStrategy}
        >
          <Form.List name="items">
            {(fields, { add, remove }) => (
              <div
                style={{
                  display: "flex",
                  gap: 30,
                  flexDirection: "column",
                }}
              >
                <Row gutter={[16, 16]}>
                  {fields.map((field, index) => (
                    <Col span={24}>
                      {/*  */}
                      <SortableItem
                        id={items[index]?.id}
                        index={index}
                        field={field}
                        remove={remove}
                        showRemoveOption={true}
                        cardTitle="Header Item"
                      />
                    </Col>
                  ))}
                </Row>
                {fields.length < 6 && (
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Header Item
                    </Button>
                  </Form.Item>
                )}
              </div>
            )}
          </Form.List>
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default HeaderDraggableSort;
