import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Button, Upload, message } from "antd";
import React, { useState, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import DraggableUploadListItem from "./DraggableUploadListItem";
import UploadUtils from "./uploadUtils";

const SortableUpload = ({ fileList = [], onChange, onRemove }) => {
  const [files, setFiles] = useState(fileList);

  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  useEffect(() => {
    if (fileList?.length > 0) {
      setFiles(fileList);
    }
  }, [fileList]);
  const handleDragEnd = (event) => {
    const { active, over } = event;
    // console.log("active", event);

    if (!over) {
      const oldIndex = files.findIndex((item) => item.uid === active.id);
      const newFiles = arrayMove(files, oldIndex, 0);
      setFiles(newFiles);
      onChange({ fileList: newFiles });
    } else if (active.id !== over.id) {
      const oldIndex = files.findIndex((item) => item.uid === active.id);
      const newIndex = files.findIndex((item) => item.uid === over.id);
      const newFiles = arrayMove(files, oldIndex, newIndex);
      setFiles(newFiles);
      onChange({ fileList: newFiles });
    }
  };

  const handleChange = ({ fileList }) => {
    setFiles(fileList);
    onChange({ fileList });
  };

  // console.log(files);

  return (
    <DndContext sensors={[sensor]} onDragEnd={handleDragEnd}>
      <SortableContext
        items={files?.map((file) => file.uid)}
        strategy={verticalListSortingStrategy}
      >
        <Upload
          {...UploadUtils.buttonPreviewMultipleProps}
          fileList={files}
          onChange={handleChange}
          onRemove={onRemove}
          itemRender={(originNode, file) => (
            <DraggableUploadListItem originNode={originNode} file={file} />
          )}
        >
          {/* <Button icon={<UploadOutlined />}>Click to Upload</Button> */}
          <UploadUtils.CardContent />
        </Upload>
      </SortableContext>
    </DndContext>
  );
};

export default SortableUpload;
