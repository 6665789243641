import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import AuthChecker from "../../../components/authChecker";
import roleAndPermission from "../../../constants/permissions";
import { AiOutlineCopy } from "react-icons/ai";
import routes from "../../../constants/routes";
// import requests from "../../../utilities/api";
import {
  Tag,
  Spin,
  Skeleton,
  Button,
  Form,
  Row,
  Col,
  Card,
  Segmented,
  Input,
  Select,
  Radio,
  Space,
  Checkbox,
  Alert,
  DatePicker,
  TimePicker,
  Flex,
  message,
  Tooltip,
  Image,
  InputNumber,
} from "antd";
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";
import PageHeader from "../../../components/pageHeader";
import requests from "../../../utilities/api";
import endpoints from "../../../constants/endpoints";
import BrowseBox from "../../../components/BrowseBox";

const DiscountAdd = () => {
  const [params] = useSearchParams();
  const schema = params.get("name");
  const discount_for = params.get("for");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [adding, setAdding] = useState(false);

  const [minRequirement, setMinRequirement] = useState(() =>
    schema !== "Buy X get Y" ? 1 : 3
  );
  const [discountUses, setDiscountUses] = useState([]);
  // const [discountedAt, setDiscountedAt] = useState("percentage");
  const [conditionTypeIds, setConditionTypeIds] = useState([]);
  const [customerConditionTypeIds, setCustomerConditionTypeIds] = useState([]);
  const [code, setCode] = useState();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}/${month}/${day}`;
  };

  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const endDateDisable = (current) => {
    if (!startDate) {
      setEndDate(null);
      return true;
    }
    const start = dayjs(startDate, "YYYY-MM-DD");
    return current && current < start.startOf("day");
  };

  const navigate = useNavigate();
  const isTimeFormat = (value) => {
    const regex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/; // Matches HH:mm:ss format
    return regex.test(value);
  };

  const addDiscount = async (value) => {
    const api = requests();
    try {
      setAdding(true);

      value.schema = schema;
      value.for = discount_for;

      value.valid_from = dayjs(value.valid_from).format("YYYY-MM-DD");
      value.valid_until = dayjs(value.valid_until).format("YYYY-MM-DD");

      if (schema === "Amount off order") {
        value.apply_to = "order";
      }
      if (schema !== "Buy X get Y") {
        value.conditions = [
          {
            condition_type: value.condition_type,
            condition_type_ids: value.condition_type_ids,
          },
        ];

        if (value.discount_method === "Discount Code") {
          if (value.customer_condition_type_ids) {
            value.conditions = [
              ...value.conditions,
              {
                condition_type: "customer",
                condition_type_ids: value.customer_condition_type_ids,
              },
            ];
          }
        }
      } else {
        value.buy_x_get_y = [
          {
            type: value.quantity ? "customer_buys" : "customer_spends",
            conditions: [
              {
                condition_type: value.condition_type,
                condition_type_ids: [1, 2],
              },
            ],
          },
          {
            type: "customer_gets",
            quantity: value.customer_quantity,
            conditions: [
              {
                condition_type: value.customer_condition_type,
                condition_type_ids: [1],
              },
            ],
          },
        ];
        delete value.customer_quantity;
        delete value.customer_condition_type;
        if (value.quantity) {
          value.buy_x_get_y[0].quantity = value.quantity;
          delete value.quantity;
        } else {
          value.buy_x_get_y[0].quantity = value.amount;
          delete value.amount;
        }

        value.conditions = [
          {
            condition_type: "customer",
            condition_type_ids: [1],
          },
        ];
      }

      if (value.apply_to === "collection") {
        value.collection_ids = value.condition_type_ids;
      }

      if (value.apply_to === "product") {
        value.product_ids = value.condition_type_ids;
      }
      if (value.apply_to === "category") {
        value.category_ids = value.condition_type_ids;
      }

      if (value.discount_method === "Discount Code") {
        if (value.customer_condition_type_ids) {
          value.customer_ids = value.customer_condition_type_ids;
        }
      }

      if (value.discount_method === "Discount Code") {
        value.discount_method = "manual";
        value.is_automatic = false;
      } else {
        value.discount_method = "automated";
        value.is_automatic = true;
      }
      if (discountUses.includes("one-use-per-customer")) {
        value.limit_per_customer = true;
      }
      value.code = value.name;

      value.apply_for_guest_user = value?.apply_for_guest_user
        ? value?.apply_for_guest_user
        : false;
      value.apply_on_sales_price = value?.apply_on_sales_price
        ? value?.apply_on_sales_price
        : false;

      if (discountUses.includes("one-use-per-customer")) {
        value.customer_usage_limit = 1;
      } else {
        value.customer_usage_limit = null;
      }

      if (!isTimeFormat(value.start_time)) {
        value.start_time = dayjs(value.start_time).format("HH:mm:ss");
      }
      if (!isTimeFormat(value.end_time)) {
        value.end_time = dayjs(value.end_time).format("HH:mm:ss");
      }

      value.valid_from = dayjs(value.valid_from).format("YYYY-MM-DD");
      value.valid_until = dayjs(value.valid_until).format("YYYY-MM-DD");

      if (value?.customer_eligibility === "all_individual") {
        value.customer_eligibility = "Individual";
        value.all_individual = true;
      } else {
        value.all_individual = false;
      }

      // value.status = value.status === "ACTIVE" ? true : false;
      delete value.condition_type_ids;
      delete value.condition_type;
      delete value.customer_condition_type_ids;

      const response = await api.post(endpoints.add_discount, value, true);
      if ((response.message = "success")) {
        message.success("Discount added successfully");
        navigate(routes.DISCOUNTS.path);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error.message);
    } finally {
      setAdding(false);
    }
  };

  function generateUniqueCode() {
    let code = "";
    function generateTimestampCode() {
      const timestamp = Date.now().toString(36);
      const randomPart = Math.random()
        .toString(36)
        .substring(2, 8)
        .toUpperCase();

      const code = `${timestamp}${randomPart}`;

      return code.substring(0, 15);
    }
    code = generateTimestampCode().toUpperCase();
    setCode(code);
    form.setFieldsValue({ name: code.toUpperCase() });
  }

  const handleConditionTypeIds = (value) => {
    form.setFieldsValue({
      condition_type_ids: value?.map((items) => items.id),
    });
    setConditionTypeIds(value);
  };

  const handleCustomerConditionTypeIds = (value) => {
    form.setFieldsValue({
      customer_condition_type_ids: value?.map((items) => items.id),
    });
    setCustomerConditionTypeIds(value);
  };

  const getSchemaTitle = (schema) => {
    let schemaTitle;

    switch (schema) {
      case "Amount off products":
        schemaTitle = "Product Discount";
        break;
      case "Amount off order":
        schemaTitle = "Order discount";
        break;
      case "Free shipping":
        schemaTitle = "Shipping discount";
        break;
      default:
        schemaTitle = "Unavilable";
    }
    return schemaTitle;
  };

  return (
    <AuthChecker
      permissions={[roleAndPermission.DiscountManagement.create]}
      redirectTo={-1}
    >
      <Spin spinning={loading}>
        <PageHeader
          title={
            loading ? (
              <Skeleton.Input active={true} />
            ) : (
              <div className="d-flex align-items-center">
                <Button
                  type="text"
                  size="small"
                  style={{ width: 35, height: 35, marginRight: 5 }}
                  onClick={() => navigate(-1)}
                >
                  <i
                    className="ri-arrow-left-line"
                    style={{ fontSize: 20, marginRight: 0 }}
                  ></i>
                </Button>
                {`Create ${discount_for.toLowerCase()}`}
              </div>
            )
          }
        />
        <Form
          layout="vertical"
          form={form}
          requiredMark={false}
          onFinish={addDiscount}
          scrollToFirstError
        >
          <Row gutter={[32, 24]} style={{ margin: 0, marginTop: 24 }}>
            <Col span={16}>
              {/*   ###########################          Card1  ################################ */}

              <Card
                size="small"
                title={schema}
                type="inner"
                bodyStyle={{ paddingTop: 24, paddingBottom: 0 }}
                extra={<>{getSchemaTitle(schema)}</>}
              >
                <Row gutter={[24]} style={{ margin: 0 }}>
                  <Col span={24}>
                    <Form.Item
                      name="discount_method"
                      label="Method"
                      initialValue={"Discount Code"}
                    >
                      <Segmented
                        options={["Discount Code", "Automatic discount"]}
                        onChange={(e) => {
                          if (e === "Discount Code") {
                            setMinRequirement(1);
                          } else {
                            setMinRequirement(2);
                          }
                          setCode("");
                          form.setFieldsValue({ name: "" });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.discount_method !==
                        currentValues.discount_method
                      }
                      noStyle
                    >
                      {({ getFieldValue }) => {
                        const method = getFieldValue("discount_method");
                        return (
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "This field is required",
                              },
                            ]}
                            name="name"
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                fontSize: "0.87rem",
                                minWidth: "100%",
                                paddingBottom: "5px",
                              }}
                            >
                              <span>
                                {`${
                                  method === "Discount Code"
                                    ? "Discount Code"
                                    : "Title"
                                }`}
                                <span style={{ color: "red" }}> *</span>
                              </span>
                              {method === "Discount Code" && (
                                <button
                                  style={{
                                    // marginLeft: "10px",
                                    backgroundColor: "white",
                                    border: "none",
                                    color: "blue",
                                    cursor: "pointer",
                                  }}
                                  type="button"
                                  onClick={generateUniqueCode}
                                >
                                  Generate random code
                                </button>
                              )}
                            </div>

                            <Input
                              value={code}
                              onChange={(e) => {
                                setCode(e.target.value);
                                form.setFieldsValue({ name: e.target.value });
                              }}
                            />
                            <span style={{ color: "gray" }}>
                              {method === "Discount Code"
                                ? "Customers must enter this code at checkout."
                                : "Customers will see this in their cart and at checkout."}
                            </span>
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label=" "
                      name="apply_on_sales_price"
                      initialValue={true}
                      valuePropName="checked"
                    >
                      <Checkbox>
                        <b>Discount apply on sales price</b>
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="active"
                      initialValue={true}
                      label={
                        <span>
                          Status
                          <span style={{ color: "red" }}> *</span>
                        </span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                    >
                      <Select
                        options={[
                          {
                            label: "Active",
                            value: true,
                          },
                          {
                            label: "Inactive",
                            value: false,
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>

              {/*  */}

              {/*   ###########################          Card2 Discounted value ############################ */}
              {(schema === "Amount off products" ||
                schema === "Amount off order") && (
                <Card
                  size="small"
                  title={"Discount value"}
                  type="inner"
                  style={{ marginTop: 24 }}
                  bodyStyle={{ paddingTop: 24, paddingBottom: 0 }}
                >
                  <Row gutter={[24]} style={{ margin: 0 }}>
                    <Col span={18}>
                      <Form.Item
                        name="type"
                        initialValue={"percentage"}
                        rules={[
                          {
                            required: true,
                            message: "this field is required",
                          },
                        ]}
                      >
                        <Select
                          options={[
                            {
                              label: "Percentage",
                              value: "percentage",
                            },
                            {
                              label: "Fixed Amount",
                              value: "fixed",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.type !== currentValues.type
                        }
                        noStyle
                      >
                        {({ getFieldValue }) => {
                          const type = getFieldValue("type");

                          return (
                            <Form.Item
                              name={"value"}
                              rules={[
                                {
                                  required: true,
                                  message: "this field is required",
                                },

                                {
                                  type: "number",
                                  min: 1,
                                  transform(value) {
                                    return value ? Number(value) : 0;
                                  },
                                  message:
                                    "Value should not be negative or Zero",
                                },
                              ]}
                            >
                              {type === "percentage" ? (
                                <InputNumber
                                  min={1}
                                  max={100}
                                  addonAfter={"%"}
                                  type="number"
                                />
                              ) : (
                                <InputNumber
                                  min={1}
                                  addonAfter={process.env.REACT_APP_CURRENCY}
                                  // prefix="$"
                                  addonBefore={"$"}
                                  type="number"
                                />
                              )}
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>

                    {schema === "Amount off order" ? (
                      <></>
                    ) : (
                      <Col span={24}>
                        <Form.Item
                          name="apply_to"
                          label="Applies to"
                          initialValue={"collection"}
                        >
                          <Select
                            options={[
                              {
                                label: "Specific Catalogs",
                                value: "collection",
                              },
                              {
                                label: "Specific Products",
                                value: "product",
                              },
                              {
                                label: "Specific Categories",
                                value: "category",
                              },
                            ]}
                            onChange={(e) => {
                              setConditionTypeIds([]);
                              form.setFieldValue({ condition_type_ids: [] });
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.apply_to !== currentValues.apply_to
                          }
                          noStyle
                        >
                          {({ getFieldValue }) => {
                            const apply_to = getFieldValue("apply_to");
                            return (
                              <>
                                <Form.Item
                                  name={"condition_type_ids"}
                                  rules={[
                                    {
                                      required: true,
                                      message: "this field is required",
                                      // validator: (_, value) =>
                                      //     value && value.length > 0
                                      //         ? Promise.resolve()
                                      //         : Promise.reject(new Error('Please select at least one ID!')),
                                    },
                                  ]}
                                >
                                  <BrowseBox
                                    searchfor={`${
                                      apply_to === "collection"
                                        ? "catalog"
                                        : apply_to
                                    }`}
                                    conditionTypeIds={conditionTypeIds}
                                    setConditionTypeIds={handleConditionTypeIds}
                                  />
                                </Form.Item>
                              </>
                            );
                          }}
                        </Form.Item>

                        {/* <Input onClick={()=>showModal(true)} placeholder={`Search ${form.getFieldValue("condition_type")==="collection"?"catalog":form.getFieldValue("condition_type")}`}/> */}
                      </Col>
                    )}
                  </Row>
                </Card>
              )}

              {/*--------------------------------------------- minimum purchase requiment--------------------- */}
              {schema === "Buy X get Y" ? (
                <Card
                  size="small"
                  title={` ${
                    minRequirement === 3 ? "Customer buys" : "Customer Spends"
                  }`}
                  type="inner"
                  style={{ marginTop: 24 }}
                  bodyStyle={{ paddingTop: 24, paddingBottom: 24 }}
                >
                  <Row gutter={[24]} style={{ margin: 0 }}>
                    <Col span={24}>
                      <Radio.Group
                        onChange={(e) => {
                          setMinRequirement(e.target.value);
                        }}
                        value={minRequirement}
                      >
                        <Space direction="vertical">
                          <Radio value={3}>Minimum quantity of items</Radio>
                          <Radio value={2}>
                            Minimum purchase amount
                            {process.env.REACT_APP_CURRENCY}
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Col>
                    <Col span={6} style={{ marginTop: "10px" }}>
                      {minRequirement === 3 ? (
                        <Form.Item
                          name={"quantity"}
                          label={
                            <span>
                              Quantity <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          rules={[
                            {
                              required: true,
                              message: "this field is required",
                            },

                            {
                              type: "number",
                              min: 1,
                              transform(value) {
                                return value ? Number(value) : 0;
                              },
                              message: "Value should not be negative or Zero",
                            },
                          ]}
                        >
                          <InputNumber min={1} />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          name={"amount"}
                          label={
                            <span>
                              Amount <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          rules={[
                            {
                              required: true,
                              message: "this field is required",
                            },

                            {
                              type: "number",
                              min: 1,
                              transform(value) {
                                return value ? Number(value) : 0;
                              },
                              message: "Value should not be negative or Zero",
                            },
                          ]}
                        >
                          <InputNumber min={1} />
                        </Form.Item>
                      )}
                    </Col>
                    <Col span={18} style={{ marginTop: "10px" }}>
                      <Form.Item
                        name="condition_type"
                        label="Any items from"
                        initialValue={"collection"}
                      >
                        <Select
                          options={[
                            {
                              label: "Specific Catalogs",
                              value: "collection",
                            },
                            {
                              label: "Specific Products",
                              value: "product",
                            },
                            {
                              label: "Specific Categories",
                              value: "category",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} style={{ marginTop: "15px" }}>
                      <strong> Customer gets</strong>
                      <br />
                      <span>
                        {" "}
                        Customers must add the quantity of items specified below
                        to their cart.
                      </span>
                    </Col>
                    <Col span={6} style={{ marginTop: "10px" }}>
                      <Form.Item
                        name={"customer_quantity"}
                        label={
                          <span>
                            Quantity <span style={{ color: "red" }}> *</span>
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                          {
                            type: "number",
                            min: 1,
                            transform(value) {
                              return value ? Number(value) : 0;
                            },
                            message: "Number should not be negative!",
                          },
                        ]}
                      >
                        <InputNumber min={1} />
                      </Form.Item>
                    </Col>
                    <Col span={18} style={{ marginTop: "10px" }}>
                      <Form.Item
                        name="customer_condition_type"
                        label="Any items from"
                        initialValue={"collection"}
                      >
                        <Select
                          options={[
                            {
                              label: "Specific Catalogs",
                              value: "collection",
                            },
                            {
                              label: "Specific Products",
                              value: "product",
                            },
                            {
                              label: "Specific Categories",
                              value: "category",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24} style={{ marginTop: "15px" }}>
                      <strong> Discounted At</strong>
                      <br />
                    </Col>
                    <Col span={24}>
                      <>
                        <Form.Item
                          name="type"
                          rules={[
                            {
                              required: true,
                              message: "Please select a discount type!",
                            },
                          ]}
                          initialValue="percentage"
                        >
                          <Radio.Group>
                            <Space direction="vertical" size="small">
                              <Radio value="percentage">Percentage</Radio>
                              <div style={{ paddingLeft: "20px" }}>
                                <Form.Item shouldUpdate noStyle>
                                  {({ getFieldValue }) =>
                                    getFieldValue("type") === "percentage" && (
                                      <Form.Item
                                        name="value"
                                        rules={[
                                          {
                                            required: true,
                                            message: "This field is required",
                                          },
                                          {
                                            type: "number",
                                            min: 1,
                                            transform(value) {
                                              return value ? Number(value) : 0;
                                            },
                                            message:
                                              "Value should not be negative or zero",
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          min={1}
                                          addonAfter="%"
                                          style={{ width: "100%" }}
                                        />
                                      </Form.Item>
                                    )
                                  }
                                </Form.Item>
                              </div>

                              <Radio value="fixed">Amount off each</Radio>

                              <div style={{ paddingLeft: "20px" }}>
                                <Form.Item shouldUpdate noStyle>
                                  {({ getFieldValue }) =>
                                    getFieldValue("type") === "fixed" && (
                                      <Form.Item
                                        name="value"
                                        rules={[
                                          {
                                            required: true,
                                            message: "This field is required",
                                          },
                                          {
                                            type: "number",
                                            min: 1,
                                            transform(value) {
                                              return value ? Number(value) : 0;
                                            },
                                            message:
                                              "Value should not be negative or zero",
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          min={1}
                                          addonAfter={
                                            process.env.REACT_APP_CURRENCY
                                          }
                                          style={{ width: "100%" }}
                                        />
                                      </Form.Item>
                                    )
                                  }
                                </Form.Item>
                              </div>

                              <Radio value="free">Free</Radio>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                      </>
                    </Col>
                  </Row>
                </Card>
              ) : (
                <Card
                  size="small"
                  title={"Minimum purchase requirements"}
                  type="inner"
                  style={{ marginTop: 24 }}
                  bodyStyle={{ paddingTop: 24, paddingBottom: 24 }}
                >
                  <Row gutter={[24]} style={{ margin: 0 }}>
                    <Col span={16}>
                      <Radio.Group
                        onChange={(e) => {
                          setMinRequirement(e.target.value);
                        }}
                        value={minRequirement}
                      >
                        <Space direction="vertical">
                          <Form.Item
                            shouldUpdate={(prevValues, currentValues) =>
                              prevValues.discount_method !==
                              currentValues.discount_method
                            }
                            noStyle
                          >
                            {({ getFieldValue }) => {
                              const method = getFieldValue("discount_method");
                              if (method !== "Discount Code") {
                                return <></>;
                              } else {
                                return (
                                  <Radio value={1}>
                                    No minimum requirement
                                  </Radio>
                                );
                              }
                            }}
                          </Form.Item>

                          <Radio value={2}>
                            Minimum Purchase Amount
                            {process.env.REACT_APP_CURRENCY}
                          </Radio>
                          {minRequirement === 2 && (
                            <div
                              style={{
                                width: "100%",
                                paddingLeft: "20px",
                              }}
                            >
                              <Form.Item
                                name="minimum_order_amount"
                                rules={[
                                  {
                                    required: true,
                                    message: "this field is required",
                                  },

                                  {
                                    type: "number",
                                    min: 1,
                                    transform(value) {
                                      return value ? Number(value) : 0;
                                    },
                                    message:
                                      "Value should not be negative or Zero",
                                  },
                                ]}
                              >
                                <>
                                  <InputNumber
                                    onChange={(e) => {
                                      form.setFieldsValue({
                                        minimum_order_amount: "",
                                      });
                                      form.setFieldsValue({
                                        minimum_order_amount: e,
                                      });
                                    }}
                                    min={1}
                                    addonBefore={"$"}
                                  />
                                  {schema === "Amount off order" ||
                                  schema === "Free shipping" ? (
                                    <>
                                      <br />
                                      <span style={{ color: "grey" }}>
                                        Applies to all products.
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <br />
                                      {form.getFieldValue("condition_type") ===
                                        "collection" && (
                                        <span style={{ color: "grey" }}>
                                          Applies only to selected catalogs.
                                        </span>
                                      )}
                                      {form.getFieldValue("condition_type") ===
                                        "product" && (
                                        <span style={{ color: "grey" }}>
                                          Applies only to selected products.
                                        </span>
                                      )}
                                      {form.getFieldValue("condition_type") ===
                                        "category" && (
                                        <span style={{ color: "grey" }}>
                                          Applies only to selected categories.
                                        </span>
                                      )}
                                    </>
                                  )}
                                </>
                              </Form.Item>
                            </div>
                          )}
                          <Radio value={3}>Minimum quantity of items</Radio>
                          <div
                            style={{
                              width: "100%",
                              paddingLeft: "20px",
                            }}
                          >
                            {minRequirement === 3 && (
                              <Form.Item
                                name="minimum_quantity"
                                rules={[
                                  {
                                    required: true,
                                    message: "This field is required",
                                  },
                                  {
                                    type: "number",
                                    min: 1,
                                    transform(value) {
                                      return value ? Number(value) : 0;
                                    },
                                    message:
                                      "Value should not be negative or zero",
                                  },
                                ]}
                              >
                                <>
                                  <InputNumber
                                    onChange={(e) => {
                                      form.setFieldsValue({
                                        minimum_quantity: "",
                                      });
                                      form.setFieldsValue({
                                        minimum_quantity: e,
                                      });
                                    }}
                                    min={1}
                                    addonAfter={process.env.REACT_APP_CURRENCY}
                                  />
                                  {schema === "Amount off order" ||
                                  schema === "Free shipping" ? (
                                    <>
                                      <br />
                                      <span style={{ color: "grey" }}>
                                        Applies to all products.
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <br />
                                      {form.getFieldValue("condition_type") ===
                                        "collection" && (
                                        <span style={{ color: "grey" }}>
                                          Applies only to selected catalogs.
                                        </span>
                                      )}
                                      {form.getFieldValue("condition_type") ===
                                        "product" && (
                                        <span style={{ color: "grey" }}>
                                          Applies only to selected products.
                                        </span>
                                      )}
                                      {form.getFieldValue("condition_type") ===
                                        "category" && (
                                        <span style={{ color: "grey" }}>
                                          Applies only to selected categories.
                                        </span>
                                      )}
                                    </>
                                  )}
                                </>
                              </Form.Item>
                            )}
                          </div>
                        </Space>
                      </Radio.Group>
                    </Col>
                  </Row>
                </Card>
              )}

              {
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.discount_method !== currentValues.discount_method
                  }
                  noStyle
                >
                  {({ getFieldValue }) => {
                    const method = getFieldValue("discount_method");
                    return (
                      <>
                        {method === "Discount Code" && (
                          <>
                            {/* -----------------------------Customer Eligibility-------------------------------------------- */}
                            <Card
                              size="small"
                              title={"Customer Eligibility"}
                              type="inner"
                              style={{ marginTop: 24 }}
                              bodyStyle={{
                                paddingTop: 12,
                                paddingBottom: 0,
                                paddingLeft: 12,
                                paddingRight: 12,
                              }}
                            >
                              <Row gutter={[24]} style={{ margin: 0 }}>
                                <Col span={12}>
                                  <Form.Item
                                    name={"customer_eligibility"}
                                    initialValue={"Individual"}
                                  >
                                    <Radio.Group
                                      onChange={(e) => {
                                        if (e.target.value === "Business") {
                                          form.setFieldsValue({
                                            customer_condition_type_ids: [],
                                          });
                                          setCustomerConditionTypeIds([]);
                                        }
                                      }}
                                    >
                                      <Space direction="vertical">
                                        <Radio value={"Individual"}>
                                          Individual User
                                        </Radio>

                                        <Radio value={"Business"}>
                                          Business User
                                        </Radio>

                                        <Radio value={"all_individual"}>
                                          All User
                                        </Radio>
                                      </Space>
                                    </Radio.Group>
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    shouldUpdate={(prevValues, currentValues) =>
                                      prevValues.customer_eligibility !==
                                      currentValues.customer_eligibility
                                    }
                                    noStyle
                                  >
                                    {({ getFieldValue }) => {
                                      const customer_eligibility =
                                        getFieldValue("customer_eligibility");
                                      return (
                                        <>
                                          {customer_eligibility ===
                                          "Individual" && <Form.Item
                                          name="apply_for_guest_user"
                                          initialValue={false}
                                          valuePropName="checked"
                                        >
                                          <Checkbox disabled="">
                                            <span>Guest User</span>
                                          </Checkbox>
                                        </Form.Item>}
                                        </>
                                      );
                                    }}
                                  </Form.Item>
                                </Col>
                                <Col span={24}>
                                  <Form.Item
                                    shouldUpdate={(prevValues, currentValues) =>
                                      prevValues.customer_eligibility !==
                                      currentValues.customer_eligibility
                                    }
                                    noStyle
                                  >
                                    {({ getFieldValue }) => {
                                      const customer_eligibility =
                                        getFieldValue("customer_eligibility");
                                      return (
                                        <>
                                          {customer_eligibility ===
                                            "Individual" && (
                                            <Form.Item
                                              name={
                                                "customer_condition_type_ids"
                                              }
                                              // rules={[
                                              //   {
                                              //     required: true,
                                              //     message:
                                              //       "this field is required",
                                              //     // validator: (_, value) =>
                                              //     //     value && value.length > 0
                                              //     //         ? Promise.resolve()
                                              //     //         : Promise.reject(new Error('Please select at least one ID!')),
                                              //   },
                                              // ]}
                                            >
                                              <BrowseBox
                                                searchfor={`customers`}
                                                conditionTypeIds={
                                                  customerConditionTypeIds
                                                }
                                                setConditionTypeIds={
                                                  handleCustomerConditionTypeIds
                                                }
                                              />
                                            </Form.Item>
                                          )}
                                          {customer_eligibility ===
                                            "Business" && (
                                            <Form.Item
                                              name={"tier_type"}
                                              initialValue={"tier4"}
                                              label={
                                                <span>
                                                  Select Tier Type{" "}
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    *
                                                  </span>
                                                </span>
                                              }
                                            >
                                              <Select
                                                options={[
                                                  {
                                                    label: "Tier 1",
                                                    value: "tier1",
                                                  },
                                                  {
                                                    label: "Tier 2",
                                                    value: "tier2",
                                                  },
                                                  {
                                                    label: "Tier 3",
                                                    value: "tier3",
                                                  },
                                                  {
                                                    label: "Tier 4",
                                                    value: "tier4",
                                                  },
                                                ]}
                                              />
                                            </Form.Item>
                                          )}
                                        </>
                                      );
                                    }}
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Card>

                            {/* --------------------------------------- Maximum discount uses----------------------------------- */}
                            <Card
                              size="small"
                              title={"Maximum discount uses"}
                              type="inner"
                              style={{ marginTop: 24 }}
                              bodyStyle={{
                                padding: 24,
                                paddingLeft: 12,
                                paddingRight: 12,
                              }}
                            >
                              <Row gutter={[24]} style={{ margin: 0 }}>
                                <Col span={18}>
                                  <Checkbox.Group
                                    style={{
                                      width: "100%",
                                    }}
                                    value={discountUses}
                                    onChange={(value) => setDiscountUses(value)}
                                  >
                                    <Space direction="vertical">
                                      <Checkbox value="number-of-times-discount">
                                        Limit number of times this discount can
                                        be used in total
                                      </Checkbox>
                                      {discountUses.includes(
                                        "number-of-times-discount"
                                      ) ? (
                                        <div style={{ paddingLeft: "20px" }}>
                                          <Form.Item
                                            name={"total_usage_limit"}
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "this field is required",
                                              },
                                              {
                                                type: "number",
                                                min: 1,
                                                transform(value) {
                                                  return value
                                                    ? Number(value)
                                                    : 0;
                                                },
                                                message:
                                                  "Number should not be negative!",
                                              },
                                            ]}
                                          >
                                            <InputNumber
                                              min={1}
                                              onChange={(e) =>
                                                form.setFieldsValue({
                                                  usage_limit: e,
                                                })
                                              }
                                            />
                                          </Form.Item>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                      <Checkbox value="one-use-per-customer">
                                        Limit to one use per customer
                                      </Checkbox>
                                    </Space>
                                  </Checkbox.Group>
                                </Col>
                              </Row>
                            </Card>
                          </>
                        )}
                      </>
                    );
                  }}
                </Form.Item>
              }

              {/* ------Active Time */}
              <Card
                size="small"
                title={"Active Dates"}
                type="inner"
                style={{ marginTop: 24 }}
                bodyStyle={{ padding: 24 }}
              >
                <Row gutter={[24]}>
                  <Col span={18}>
                    <Form.Item
                      name={"valid_from"}
                      label="Start Date"
                      initialValue={dayjs(getCurrentDate(), "YYYY/MM/DD")}
                      rules={[
                        { required: true, message: "this field is required" },
                      ]}
                    >
                      <DatePicker
                        disabledDate={disabledDate}
                        format={"YYYY-MM-DD"}
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={"start_time"}
                      label="Start Time"
                      initialValue={dayjs()}
                      rules={[
                        { required: true, message: "this field is required" },
                      ]}
                    >
                      <TimePicker  format={"HH:mm:ss"}/>
                    </Form.Item>
                  </Col>
                  {/* <Col span={24}>
                    <Checkbox
                      checked={endDate}
                      onChange={(e) => {
                        setEndDate(e.target.checked);
                      }}
                    >
                      Set end date
                    </Checkbox>
                  </Col> */}

                  <Col span={18}>
                    <Form.Item
                      name={"valid_until"}
                      label="End Date"
                      initialValue={dayjs().add(1, "day")}
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                    >
                      <DatePicker
                        disabledDate={endDateDisable}
                        format={"YYYY-MM-DD"}
                        value={endDate}
                            onChange={(date) => setEndDate(date)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={"end_time"}
                      label="End Time"
                      initialValue={dayjs()}
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                    >
                      <TimePicker format={"HH:mm:ss"}/>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col span={8}>
              {/* #########################Summary ############################## */}
              <Card
                size="small"
                title={"Summary"}
                type="inner"
                // style={{ paddingTop: 24, paddingBottom: 24 }}
                bodyStyle={{ padding: 24 }}
              >
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.discount_method !==
                      currentValues.discount_method ||
                    prevValues.total_usage_limit !==
                      currentValues.total_usage_limit ||
                    prevValues.active !== currentValues.active
                  }
                  noStyle
                >
                  {({ getFieldValue }) => {
                    const method = getFieldValue("discount_method");
                    const usage_limit = getFieldValue("total_usage_limit");
                    const status = getFieldValue("active");
                    return (
                      <>
                        {method === "Discount Code" ? (
                          <div style={{ paddingBottom: "0px" }}>
                            {code ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 10,
                                  }}
                                >
                                  <strong>{code}</strong>
                                  <Tooltip title="Copy Code" placement="right">
                                    <AiOutlineCopy
                                      size={16}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        navigator.clipboard.writeText(code);
                                        message.success("Copied to clipboard");
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </Tooltip>
                                </div>
                                <span>{status ? "ACTIVE" : "INACTIVE"}</span>
                              </div>
                            ) : (
                              <strong>No discount code yet</strong>
                            )}
                            <div style={{ marginTop: "10px" }}>
                              <strong>Type and method</strong>
                              <ul>
                                <li>{schema}</li>
                                <li>Code</li>
                              </ul>
                            </div>
                            {/* <div>
                              <strong style={{ marginTop: "10px" }}>
                                Details
                              </strong>
                              {code ? (
                                <ul style={{ marginBottom: 0}}>
                                  <li>For Online Store</li>
                                  {minRequirement === 1 ? (
                                    <li>No minimum purchase requirement</li>
                                  ) : (
                                    <></>
                                  )}
                                  <li>All customers</li>
                                  <li>
                                    {discountUses.length !== 0 ? (
                                      <>
                                        {discountUses.includes(
                                          "number-of-times-discount"
                                        ) &&
                                          usage_limit && (
                                            <>
                                              Limit of {usage_limit} uses
                                              {discountUses.length > 1
                                                ? ","
                                                : ""}
                                            </>
                                          )}
                                        {discountUses.includes(
                                          "one-use-per-customer"
                                        ) && "One per customer"}
                                      </>
                                    ) : (
                                      "No usage limits"
                                    )}
                                  </li>

                                  <li>Active from today</li>
                                </ul>
                              ) : (
                                <ul>
                                  <li>Can’t combine with other discounts</li>
                                </ul>
                              )}
                            </div> */}
                            {/* <div style={{ marginTop: "10px" }}>
                              <strong>Performance</strong>
                              <br />
                              <span>Discount is not active yet</span>
                            </div> */}
                          </div>
                        ) : (
                          <div style={{ paddingBottom: "0px" }}>
                            {code ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 10,
                                  }}
                                >
                                  <strong>{code}</strong>
                                  <Tooltip title="Copy Code" placement="right">
                                    <AiOutlineCopy
                                      size={16}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        navigator.clipboard.writeText(code);
                                        message.success("Copied to clipboard");
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </Tooltip>
                                </div>
                                <span>{status ? "ACTIVE" : "INACTIVE"}</span>
                              </div>
                            ) : (
                              <strong>No title yet</strong>
                            )}
                            <div style={{ marginTop: "10px" }}>
                              <strong>Type and method</strong>
                              <ul>
                                <li>{schema}</li>
                                <li>Automatic</li>
                              </ul>
                            </div>
                            {/* <div>
                              <strong style={{ marginTop: "10px" }}>
                                Details
                              </strong>
                              {code ? (
                                <ul  style={{ marginBottom: 0}}>
                                  <li>For Online Store</li>
                                  {minRequirement === 1 ? (
                                    <li>No minimum purchase requirement</li>
                                  ) : (
                                    <></>
                                  )}
                                  <li>All customers</li>
                                  <li>
                                    {discountUses.length !== 0 ? (
                                      <>
                                        {discountUses.includes(
                                          "number-of-times-discount"
                                        ) &&
                                          usage_limit && (
                                            <>
                                              Limit of {usage_limit} uses
                                              {discountUses.length > 1
                                                ? ","
                                                : ""}
                                            </>
                                          )}
                                        {discountUses.includes(
                                          "one-use-per-customer"
                                        ) && "One per customer"}
                                      </>
                                    ) : (
                                      "No usage limits"
                                    )}
                                  </li>

                                  <li>Active from today</li>
                                </ul>
                              ) : (
                                <ul>
                                  <li>Can’t combine with other discounts</li>
                                </ul>
                              )}
                            </div> */}
                            {/* <div style={{ marginTop: "10px" }}>
                              <strong>Performance</strong>
                              <br />
                              <span>Discount is not active yet</span>
                            </div> */}
                          </div>
                        )}
                      </>
                    );
                  }}
                </Form.Item>
              </Card>
            </Col>
          </Row>
          <div className="sticky-footer">
            <Flex justify="end" style={{ padding: 24 }} gap={16}>
              <Button onClick={() => navigate(-1)}>Cancel</Button>

              <Form.Item noStyle>
                {loading ? (
                  <Skeleton.Button />
                ) : adding ? (
                  <Button type="primary" htmlType="submit" disabled={adding}>
                    <Spin indicator={<LoadingOutlined spin />} size="small" />{" "}
                    Adding ...
                  </Button>
                ) : (
                  <Button type="primary" htmlType="submit">
                    Add Discount
                  </Button>
                )}
              </Form.Item>
            </Flex>
          </div>
        </Form>
      </Spin>
    </AuthChecker>
  );
};

export default DiscountAdd;
